
import md5 from "js-md5";
import http from '../request.js'
import {getToken,setToken,removeToken} from './auth.js'
import hiSetting from "@/hiSetting.js";
import utils from "../utils"

var request = http;

// if (window.HIVUI_SETTING)
//     request = window.HIVUI_SETTING.request;
// else {
//     window.HIVUI_SETTING = hiSetting
// }

/** 
   * 登陆
   * @param {Id} 系统Id
   */
export function login(userName, pwd) {
    var data = { username: userName.trim(), password: md5(pwd) };
    let _promise=request({
        url: window.HIVUI_SETTING.login||(window.HIVUI_SETTING.serverUrl+"/login/sso-login"),
        method: 'post',
        data: data
    });
    _promise.then(res=>{
        const data = res
        if(data.token){
            setToken(data.token);
        }
        if(location.hash=="#miniLogin"){//外部调用登录接口时小登录判断
            if(utils.StringFunc.getUrlValue("questType")=="ajax"){
                top.window.SysPage&&top.window.SysPage.closeMiniLogin(utils.StringFunc.getUrlValue("isRefresh"));
            }else{
                location.reload();
            }
        }
    });
    return _promise;
}


/** 
 * 登出
 */
export function logout() {
    if(!getToken()){
        return new Promise((resolve, reject)=>{
            resolve();
        });
    }
    let _promise=request({
        url: window.HIVUI_SETTING.logout||(window.HIVUI_SETTING.serverUrl+"/login/sso-logout"),
        method: 'post'
    });
    _promise.then(res=>{
        removeToken();
    });
    return _promise;
}

/** 
* 获取用户信息
* @param {data} 
*/
export function getInfo(data) {
    return request({
        url: window.HIVUI_SETTING.userInfo,
        method: 'post',
        data: data

    })
}

export function initUser(data) {
    let promise = getInfo(data)
    promise.then(res => {
        if (!window.eap)
            window.eap = {}
        window.eap.userInfo = res.dataPack;
    })
    return promise;
}
//修改密码
export function modifyPw(oldPwd, newPwd) {
    return request({
      url: window.HIVUI_SETTING.serverUrl + "/sys/user/update-pwd",
      method: 'post',
      data: { oldPwd, newPwd }
    })
  }
//获取岗位列表
export function getBzList(data) {
    return request({
    url: window.HIVUI_SETTING.serverUrl + "/sys/auth/func-multi-bz",
    method: 'post',
    data: data
    })
}


export default {
    login,
    logout,
    getInfo,
    modifyPw,
    getBzList,
}