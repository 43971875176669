
//调用org方法时一定要在service.js/initUser()之后

function getCurrentUser() {
    return window.eap.userInfo || {};
}
function getMainUser() {
    if (window.eap.userInfo && window.eap.userInfo.main)
        return window.eap.userInfo.main;
    else if (window.eap.userInfo)
        return window.eap.userInfo;
    return {}
}
export default {
    /**
    * 当前用户ID
    *
    * @return
    */
    userId: function () {

        return getMainUser().fuserid || "";
    },

    /**
     * 当前用户姓名
     *
     * @return
     */
    userName: function () {
        return getMainUser().fusername || "";
    },

    /**
     * 当前用户部门ID
     *
     * @return
     */
    deptId: function () {
        return getMainUser().fdeptid || "";
    },

    /**
     * 当前用户部门名称
     *
     * @return
     */
    deptName: function () {
        return getMainUser().fdeptname || "";
    },

    /**
     * 当前用户岗位ID
     *
     * @return
     */
    roleId: function () {
        return getMainUser().froleid || "";
    },


    /**
     * 当前用户岗位名称
     *
     * @return
     */
    roleName: function () {
        return getMainUser().frolename || "";
    },

    /**
     * 当前用户组织机构路径
     *
     * @return
     */
    orgPath: function () {
        return getMainUser().fuserorgpath || "";
    },
    orgName: function () {
        return getMainUser().forgname || "";
    },
    orgId: function () {
        return getMainUser().forgid || "";
    },

    //直属上级用户ID
    superId: function () {
        return getMainUser().fsuperuserid || "";
    },

    //直属上级用户姓名
    superName: function () {
        return getMainUser().fsuperbzname || "";
    },

    /***********编制******************************************************************************************* */

    //用户编制ID
    bzId: function () {

        return getCurrentUser().fbzid || "";
    },

    //用户编制名称
    bzName: function () {

        return getCurrentUser().fbzname || "";
    },


    //用户编制部门ID
    bzDeptId: function () {
        return getCurrentUser().fdeptid || "";
    },

    //用户编制部门名称
    bzDeptName: function () {
        return getCurrentUser().fdeptname || "";
    },

    //用户编制岗位ID
    bzRoleId: function () {
        return getCurrentUser().froleid || "";
    },


    //用户编制岗位名称
    bzRoleName: function () {
        return getCurrentUser().frolename || "";
    },

    //用户编制机构路径
    bzOrgPath: function () {
        return getCurrentUser().fuserorgpath || "";
    },
    //用户编制机构名称
    bzOrgName: function () {
        return getCurrentUser().forgname || "";
    },
    //用户编制机构ID
    bzOrgId: function () {
        return getCurrentUser().forgid || ""
    },
    //当前用户所属公司的机构与部门ID
    orgDeptId:function(){
        return (getCurrentUser().forgid || "") + "." + (getCurrentUser().fdeptid || "");
    },
    //当前用户所属公司的机构与部门名称
    orgDeptName:function(){
        return (getCurrentUser().forgname || "") + "." + (getCurrentUser().fdeptname || "");
    },


    //直属上级编制ID
    bzSuperId: function () {
        return getCurrentUser().fsuperuserid || "";
    },

    //直属上级编制名称
    bzSuperName: function () {
        return getCurrentUser().fsuperbzname || "";
    },

    
    /**
     * 当前用户saas部门ID
     *
     * @return
     */
     saasDeptId: function () {
        return getCurrentUser().fdeptid.replace(window.eap.userInfo.forgid + "_", "").replace(window.eap.userInfo.forgid + "-", "");
    },
    /**
     * 当前用户saas岗位ID
     *
     * @return
     */
    saasRoleId: function () {
        return getCurrentUser().froleid.replace(window.eap.userInfo.forgid + "_", "").replace(window.eap.userInfo.forgid + "-", "");
    },

    /**********代理********************************************************************************************** */
    /**
         * 当前代理用户ID
         *
         * @return
         */
    proxyId: function () {
        if (!window.eap.userInfo)
            return "";
        return window.eap.userInfo.fuserid;
    },

    /**
     * 当前代理用户姓名
     *
     * @return
     */
    proxyName: function () {
        if (!window.eap.userInfo)
            return "";
        return window.eap.userInfo.fusername;
    },
    /**
     * 当前代理编制ID
     *
     * @return
     */
    bzProxyId: function () {
        if (!window.eap.userInfo)
            return "";
        return window.eap.userInfo.fbzid;
    },

    /**
     * 当前代理编制名称
     *
     * @return
     */
    bzProxyName: function () {
        if (!window.eap.userInfo)
            return "";
        return window.eap.userInfo.fbzname
    },

    /******************************************************************************************************** */

    /**
     * 根据岗位ID获取用户编制号列表，逗号隔开
     *
     * @param roleId
     * @return
     */
    getBzByRole: function (roleId) {

        return []
    },

    /**
     * 根据部门ID获取用户编制号列表，逗号隔开
     *
     * @param deptId
     * @return
     */
    getBzByDept: function (deptId) {

        return "";
    },

    /**
     * 根据当前用户岗位ID获取编制号列表，逗号隔开
     *
     * @return
     */
    getBzByRole: function () {
        return getBzByRole(roleId());
    },

    /**
     * 根据当前用户部门ID获取编制号列表，逗号隔开
     *
     * @return
     */
    getBzByDept: function () {
        return getBzByDept(deptId());
    },
}