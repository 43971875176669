import dataHelper from "@/base/dataHelper";
import OrgFunc from "@/eap/user/org.js";

const modelPath = "/platf/useroperate/entity/SysUseroperate.xml";

function getUrl(url) {
    let project = window.HIVUI_SETTING.project || window.HIVUI_SETTING.projectName || "";
    if (project.indexOf("/") != -1)
        project = project.split("/")[0]
    if (url.indexOf(".pro") != -1) {
        let _url = url;
        let param = "";
        if (_url.indexOf("?") != -1) {
            _url = _url.substring(0, _url.lastIndexOf("?"))
            param = url.substring(url.lastIndexOf("?"))
        }

        let extName = _url.substring(_url.lastIndexOf("."))
        url = _url.replace(/\.\w+/g, "") + extName + param;
    }
    if (project) {
        let arrUrl = url.split("/");
        if (arrUrl[0] != "")
            arrUrl[0] = project;
        else
            arrUrl[1] = project;
        url = arrUrl.join("/");
    }



    let newUrl = "";
    if (url.startsWith("/"))
        newUrl = newUrl + url;
    else
        newUrl = newUrl + "/" + url;
    if (window.HIVUI_SETTING.projectName) {
        if (url.indexOf("?") != -1)
            newUrl = newUrl + "&pn=" + window.HIVUI_SETTING.projectName;
        else
            newUrl = newUrl + "?pn=" + window.HIVUI_SETTING.projectName;
    }

    return newUrl;

}




export default {

    createNumber() {
        return "NEW-YYMMDD-9999"
    },
    //下载地址
    getDownloadUrl(url, fileName) {
        var fileExtension = url.substr(
            url.lastIndexOf(".") + 1
        );
        var file_path = "";
        if (!fileName) {
            file_path = `${window.HIVUI_SETTING.download}?pn=${window.HIVUI_SETTING.projectName}&path=${url
                }&access_token=${window.eap.user.auth.getToken()}`;
        } else {
            file_path = `${window.HIVUI_SETTING.download}?pn=${window.HIVUI_SETTING.projectName}&path=${url
                }&access_token=${window.eap.user.auth.getToken()}&name=${fileName}.${fileExtension}`;
        }
        return file_path;
    },
    //删除用户数据
    delUserData(key, type) {
        let saveHelper = new dataHelper.saveHelper(modelPath, null, {
            request: window.HIVUI_SETTING.request,
            url: window.HIVUI_SETTING.saveUrl,
            pn: window.HIVUI_SETTING.projectName,
        });
        saveHelper.remove({
            FKEY: key,
            FUSERID: OrgFunc.bzId(),
            FTYPE: type
        });
        return saveHelper.save();
    },
    //查询用户数据
    async getUserData(key, type) {
        var where = new dataHelper.queryHelper.Where({
            junction: 'and'
        });
        where.addCondition("FKEY", key,
            "dbString");
        where.addCondition("FTYPE", type,
            "dbString");
        where.addCondition("FUSERID", OrgFunc.bzId(),
            "dbString");
        var param = new dataHelper.queryHelper.Param({
            where: where
        });
        let result = await dataHelper.queryHelper.query(param, modelPath, null, {
            request: window.HIVUI_SETTING.request,
            url: window.HIVUI_SETTING.queryUrl,
            pn: window.HIVUI_SETTING.projectName
        });
        if (result.dataPack.rows.length > 0)
            return result.dataPack.rows[0].FVALUE;
        return null;
    },
    //保存数据
    saveUserData(key, data, type, desc) {
        let saveHelper = new dataHelper.saveHelper(modelPath, null, {
            request: window.HIVUI_SETTING.request,
            url: window.HIVUI_SETTING.saveUrl,
            pn: window.HIVUI_SETTING.projectName,
        });
        saveHelper.override({
            FKEY: key,
            FUSERID: OrgFunc.bzId(),
            FTYPE: type
        }, {
            FKEY: key,
            FUSERID: OrgFunc.bzId(),
            FTYPE: type,
            FVALUE: typeof data == "string" ? data : JSON.stringify(data),
            FNAME: desc || ""
        });
        return saveHelper.save();
    }
    , getUrl,
    getResUrl(url) {
        return deployDir + getUrl(url);
    }
}