
import md5 from "js-md5";
import DateFunc from "@/base/utils/src/date";
import pinyinUtil from './vue-py.js';
import jrQrcode from "jr-qrcode"
import JsBarcode from "jsbarcode"
/**
   * showdoc
   * @catalog API/工具/Date
   * @title 字符串基础类
   * @className ClientString
   * @modifier static
   * @method StringFunc
   * @demo 
   */
const STR = {
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 生成MD5
	   * @description 生成MD5
	   * @method md5
	   * @param str 必选 String 需生成的字符 
	   * @return String
	   * @number 60  
	   */
	md5: function (str) {
		return md5(str);
	},
	/**
   * showdoc
  * @catalog API/工具/String
   * @title 获取中文首字母拼音
   * @description  获取中文首字母拼音
   * @method getFirstPY
   * @param str 必选 String 需生成的字符 
   * @return String
   * @number 60  
   */
	getFirstPY: function (str) {
		if (!str)
			return "";
		let SX = '', pinyin;
		str = str.toUpperCase();
		if (str.constructor == Array) {
			pinyin = [];
			for (let i = 0; i < str.length; i++) {
				pinyin.push(pinyinUtil.chineseToPinYin(str[i]));
			}
		}
		else
			pinyin = pinyinUtil.chineseToPinYin(str);

		if (pinyin.constructor == Array) {
			let result = [];
			for (let j = 0; j < pinyin.length; j++) {
				let tempPY = pinyin[j], tempSX = "";
				for (let i = 0; i < tempPY.length; i++) {
					let c = tempPY.charAt(i);
					if (/^[A-Z0-9]+$/.test(c)) {
						tempSX += c;
					}
				}
				result.push(tempSX.toLowerCase());
			}
			return result;
		}
		else {
			for (let i = 0; i < pinyin.length; i++) {
				let c = pinyin.charAt(i);
				if (/^[A-Z0-9]+$/.test(c)) {
					SX += c;
				}
			}
			return SX.toLowerCase();
		}

	},
	/**
   * showdoc
  * @catalog API/工具/String
   * @title 下载地址
   * @description  获取下载地址
   * @url getDownloadUrl(url,fileName)
   * @method getDownloadUrl
   * @param url 必选 url 
   * @param fileName 必选 文件名
   * @return String
   * @number 60  
   */
	getDownloadUrl(url, fileName) {
		return window.eap.utils.biz.getDownloadUrl(url, fileName);;
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 获取中文全拼音
	   * @description  获取中文全拼音
	   * @url getPinyin(str)
	   * @method getPinyin
	   * @param str 必选 String 需生成的字符 
	   * @return String
	   * @number 60  
	   */
	getPinyin: function (str) {
		let result = [];
		if (!str)
			return "";
		if (str.constructor == Array) {
			for (let i = 0; i < str.length; i++) {
				result.push(pinyinUtil.chineseToPinYin(str[i]).toLowerCase());
			}
			return result;
		}
		else
			return pinyinUtil.chineseToPinYin(str).toLowerCase();
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 获取指定范围随机数
	   * @description  获取中文全拼音
	   * @url randomInt(min, max)
	   * @method randomInt
	   * @param min 必选 Int 起止数字
	   *  @param max 必选 Int 结束数字
	   * @return String
	   * @number 60  
	   */
	randomInt: function (min, max) {
		var Range = max - min;
		var Rand = Math.random();
		return (min + Math.round(Rand * Range));
	},
	/**
* showdoc
* @catalog API/工具/String
* @title 获取功能/流程路径
* @description  获取功能/流程路径
* @url getBizUrl(url)
* @method getBizUrl
* @param url 必选 url 
* @return String
* @number 60  
*/
	getBizUrl(url) {
		if (window.eap)
			return window.eap.utils.biz.getUrl(url);
		return url
	},
	/**
   * showdoc
  * @catalog API/工具/String
   * @title 获取url地址栏参数
   * @description  获取功能/流程路径
   * @method getUrlValue
   * @param name 必选 name 
   * @param url 选填 url 
   * @return String
   * @number 60  
   */
	getUrlValue: function (name, url) {
		var str = url || window.location.href;
		if (str.indexOf("&" + name) != -1 || str.indexOf("?" + name) != -1) {
			var pos_start = ""
			if (str.indexOf("?" + name) > -1)
				pos_start = str.indexOf("?" + name) + name.length + 2;
			else
				pos_start = str.indexOf("&" + name) + name.length + 2;
			var pos_end = str.indexOf("&", pos_start);
			if (pos_end == -1) {
				return str.substring(pos_start);
			} else {
				return str.substring(pos_start, pos_end)
			}
		} else {
			return "";
		}
	},
	/**
   * showdoc
  * @catalog API/工具/String
   * @title 设置url地址栏参数
   * @description  获取功能/流程路径
   * @url getUrlValue(name, url)
   * @method setUrlValue
   * @param url 选填 url 
   * @param name 选填 name 
   * @param pValue 选填 pValue 
   * @return String
   * @number 60  
   */
	setUrlValue: function (url, pName, pValue) {
		if (url == null || url == '') {
			return '';
		}
		var arrUrl = url.split('?');
		if (arrUrl.length <= 1) {
			return url + '?' + pName + '=' + pValue;
		}
		var paramArr = arrUrl[1].split('&');
		var isAdd = true;
		for (var i = 0; i < paramArr.length; i++) {
			var valueArr = paramArr[i].split('=');
			if (valueArr[0] == pName) {
				paramArr[i] = pName + '=' + pValue;
				isAdd = false;
			}
		}
		if (isAdd) {
			paramArr[paramArr.length] = pName + '=' + pValue;
		}
		return arrUrl[0] + '?' + paramArr.join('&');
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 获取指定长度的ID
	   * @description  获取指定长度的ID
	   * @url id(len)
	   * @method id
	   * @param len 必选 Int 长度
	   * @return String
	   * @number 60  
	   */
	id: function (len) {
		return (function (len, radix) {
			var chars = '012abcdefghuwxyz34MNOPQRSTUV567ijklmnopqrst89ABCDEFGHIJKLWXYZ'
				.split('');
			// var chars = Scp.String.newGUID().replace(/-/g,"").split("");
			var uuid = [], i;
			radix = radix || chars.length;

			if (len) {
				// Compact form
				for (i = 0; i < len; i++)
					uuid[i] = chars[0 | Math.random() * radix];
			} else {
				// rfc4122, version 4 form
				var r;

				// rfc4122 requires these characters
				uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
				uuid[14] = '4';

				// Fill in random data. At i==19 set the high bits of clock
				// sequence as
				// per rfc4122, sec. 4.1.5
				for (i = 0; i < 36; i++) {
					if (!uuid[i]) {
						r = 0 | Math.random() * 16;
						uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
					}
				}
			}
			return uuid.join('');
		})(len || 6, 61);
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 获取GUID
	   * @description  获取GUID
	   * @url guid()
	   * @method guid
	   * @return String
	   * @number 60  
	   */
	guid: function () {
		var nowDateTime = new Date();
		var myRandom1 = this.randomInt(1, 1000);
		var myRandom2 = this.randomInt(1, 1000);
		var myRandom3 = this.randomInt(1, 1000);
		var decodeStr = nowDateTime.valueOf() + "-"
			+ nowDateTime.getMilliseconds() + "-" + myRandom1 + "-"
			+ myRandom2 + "-" + myRandom3;
		var encodeStr = this.md5(decodeStr);
		var guid = encodeStr.substring(0, 8) + "-" + encodeStr.substring(8, 12)
			+ "-" + encodeStr.substring(12, 16) + "-"
			+ encodeStr.substring(16, 20) + "-"
			+ encodeStr.substring(20, 32);
		return guid.toUpperCase();
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 字符格式化
	   * @description  字符格式化
	   * @url format()
	   * @method format(str,$1,$2,$3......)
	   *  @param str 必选 String 需格式化的字符
	   *  @param $1 必选 String 需替换的$1
	   *  @param $2 必选 String 需替换的$2
	   *  @param $3 必选 String 需替换的$3
	   * @return String
	   * @number 60  
	   */
	format: function () {
		if (arguments.length == 0)
			return '';
		var argArray = Array.prototype.slice.call(arguments);
		var result = argArray.shift();

		if (argArray.length == 1 && typeof (argArray[0]) == "object") {
			var args = argArray[0];
			for (var key in args) {
				if (args[key] != undefined) {
					let reg = new RegExp("({" + key + "})", "g");
					result = result.replace(reg, args[key]);
				}
			}
		} else {
			for (var i = 0; i < argArray.length; i++) {
				if (argArray[i] != undefined) {
					let reg = new RegExp("({)" + i + "(})", "g");
					result = result.replace(reg, argArray[i]);
				}
			}
		}
		return result;
	},
	controlGuid(key) {
		return (key || '').replace(/[.|#|@|*|\?|\(|\)|<|>|\{|\|\^|\$}]/gi, '_') + '_' + STR.id(8);
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 转换人民币大写
	   * @description  转换人民币大写
	   * @method upperMoney
	   *  @param numberValue 必选 numberValue 
	   * @return String
	   * @number 60  
	   */
	upperMoney(numberValue) {
		var numberValue = new String(Math.round(Math.abs(numberValue) * 100)); // 数字金额
		var chineseValue = ""; // 转换后的汉字金额
		var String1 = "零壹贰叁肆伍陆柒捌玖"; // 汉字数字
		var String2 = "万仟佰拾亿仟佰拾万仟佰拾元角分"; // 对应单位
		var len = numberValue.length; // numberValue 的字符串长度
		var Ch1; // 数字的汉语读法
		var Ch2; // 数字位的汉字读法
		var nZero = 0; // 用来计算连续的零值的个数
		var String3; // 指定位置的数值
		if (len > 15) {
			alert("超出计算范围");
			return "";
		}
		if (numberValue == 0) {
			chineseValue = "零元整";
			return chineseValue;
		}

		String2 = String2.substr(String2.length - len, len); // 取出对应位数的STRING2的值
		for (var i = 0; i < len; i++) {
			String3 = parseInt(numberValue.substr(i, 1), 10); // 取出需转换的某一位的值
			if (i != (len - 3) && i != (len - 7) && i != (len - 11)
				&& i != (len - 15)) {
				if (String3 == 0) {
					Ch1 = "";
					Ch2 = "";
					nZero = nZero + 1;
				} else if (String3 != 0 && nZero != 0) {
					Ch1 = "零" + String1.substr(String3, 1);
					Ch2 = String2.substr(i, 1);
					nZero = 0;
				} else {
					Ch1 = String1.substr(String3, 1);
					Ch2 = String2.substr(i, 1);
					nZero = 0;
				}
			} else { // 该位是万亿，亿，万，元位等关键位
				if (String3 != 0 && nZero != 0) {
					Ch1 = "零" + String1.substr(String3, 1);
					Ch2 = String2.substr(i, 1);
					nZero = 0;
				} else if (String3 != 0 && nZero == 0) {
					Ch1 = String1.substr(String3, 1);
					Ch2 = String2.substr(i, 1);
					nZero = 0;
				} else if (String3 == 0 && nZero >= 3) {
					Ch1 = "";
					Ch2 = "";
					nZero = nZero + 1;
				} else {
					Ch1 = "";
					Ch2 = String2.substr(i, 1);
					nZero = nZero + 1;
				}
				if (i == (len - 11) || i == (len - 3)) { // 如果该位是亿位或元位，则必须写上
					Ch2 = String2.substr(i, 1);
				}
			}
			chineseValue = chineseValue + Ch1 + Ch2;
		}

		if (String3 == 0) { // 最后一位（分）为0时，加上“整”
			chineseValue = chineseValue + "整";
		}

		return chineseValue;
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 分解附件
	   * @description  分解附件
	   * @url format()
	   * @method splitAttach
	   *  @param val 必选 附件值 
	   * @return String
	   * @number 60  
	   */
	splitAttach(val) {
		var list = [];
		var arr = val.split("|");
		var path = window.HIVUI_SETTING ? window.HIVUI_SETTING.review : "";
		for (var i = 0; i < arr.length; i++) {
			var item = arr[i];
			if (!item) {
				continue;
			}
			list.push({
				name: arr[i].split(";")[0],
				size: arr[i].split(";")[1],
				path: arr[i].split(";")[2],
				url: `${path}?relativePath=${arr[i].split(";")[2]}`,
			});
		}
		return list;
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 附件图片地址
	   * @description  附件图片地址
	   * @url format()
	   * @method splitAttachImgUrl
	   *  @param val 必选 附件值 
	   * @return String
	   * @number 60  
	   */
	splitAttachImgUrl(val) {
		if (!val) return;
		var list = [];
		var arr = val.split("|");
		var path = window.HIVUI_SETTING ? window.HIVUI_SETTING.review : "";
		for (var i = 0; i < arr.length; i++) {
			var item = arr[i];
			if (!item) {
				continue;
			}
			if (arr[i].split(";").length > 2) {
				list = this.splitAttach(val)
				break;
			} else {
				if (arr[0].indexOf("http://") != -1) {
					list.push({
						url: arr[0],
					});
				} else {
					list.push({
						url: `${path}?relativePath=${arr[0]}`,
					});
				}
			}
		}
		return list[0].url;
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 获取二维码
	   * @description  生成二维码Base64 URL
	   * @url getQrcode(text, options)
	   * @method getQrcode
	   * @param text 必选,要生成二维码的字符，支持中文 string 
	   * @param options 配置对象  object
	   * @return String
	   * @number 60  
	   */
	getQrcode(text, options = {}) {
		if (!text) return;
		let imgBase64 = jrQrcode.getQrBase64(text, options);
		return imgBase64
	},
	/**
	   * showdoc
	  * @catalog API/工具/String
	   * @title 获取条形码
	   * @description  生成条形码Base64 URL
	   * @url getBarcode(text, options)
	   * @method getBarcode
	   * @param text 必选,要生成条形码的字符 string
	   * @param options 配置对象  object
	   * @return String
	   * @number 60  
	   */
	getBarcode(text, options = {}) {
		if (!text) return;
		var canvas = document.createElement("canvas")
		JsBarcode(canvas, text, options)
		return canvas.toDataURL("image/png")
	}
}
export default STR

