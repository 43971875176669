import isDate from 'lodash/isDate'
import { date } from '../../../utils'
import cloneDeep from "lodash/cloneDeep"
export const paramKey = {
    //subSetkeyVal:"$subSetkeyVal",
    root: '__body',
    version: "__version",
    data: 'data',
    funcPath: "__funcpath",
    modelFile: 'modelFilePath',
    funcFile: 'fpath',
    state: '$state',
    old: '$old',
    insertStateVal: 'rsInsert',
    updateStateVal: 'rsUpdate',
    removeStateVal: 'rsDelete',
    overrideStateVal: 'rsOverride',
    normalStateVal: "rsNormal",
    viewItemId:"viewItemId"
}


/**
      * showdoc
      * @title 数据保存包
      * @className HiDataHelper
      * @description 数据保存包
      * @method SavePackHelper
      * @demo
      * let modelFile = "数据集实体路径";
      * let funcPath = "功能路径";
      * let old={FNUMBER:"DH-2210-2201",FIELD1:'data1',FIELD2:'data2'}; //原始数据中必须包含主键字段值
      * let data = {FIELD1:'d1',FIELD2:'d2'}; 
      * let saveHelper = this.createSaveHelper(modelFile,funcPath); //在页面上下文,创建saveHelper
      * saveHelper.update(old,data); //组装更新包
      * saveHelper.add({FNUMBER:"NEW-YYMMDD-9999",FIELD1:'a2',FIELD2:'a2'}); //组装新增包
      * saveHelper.remove({FNUMBER:"DH-2210-2200"}); //组装删除包,删除数据中必须包含主键字段值
      * saveHelper.save().then(res=>{ 
      *     //保存成功
      *     if(res.status==200){
      *         
      *     }
      * })
      */
export default class SavePackHelper {
    /**
    * @title 构造函数 
    * @description 保存助手构造函数
    * @method HiDataHelper
    * @param modelFile 必选 string 数据集路径
    * @param options 必选 json 请求选项
    * @param options.request 必选 axios 请求对象
    * @param options.url 必选 url 请求rul
    * @param options.pn 选填 pn 项目ID
    * @return HiDataHelper
    */
    constructor(modelFile, funcPath, { request, url, pn, viewItemId, extParam, colToRow }) {
        this.className = "SavePackHelper"
        this.modelFile = modelFile;
        this.request = request;
        this.colToRow = colToRow;
        this.viewItemId = viewItemId;
        this._appendSavePack = [];
        funcPath = (funcPath || "")
        this.url = url;
        // if (url.endsWith("/") == false && funcPath.startsWith("/") == false)
        //     this.url = url + "/" + (funcPath || "");
        // else
        //     this.url = url + (funcPath || "");
        this.funcPath = funcPath;

        this.extParam = extParam;
        this.pn = pn;

        this.dataPacks = {};
        this.dataPacks[paramKey.data] = [];
        this.dataPacks[paramKey.modelFile] = this.modelFile;

        //this.dataPacks[paramKey.funcPath] = this.funcPath;

    }

    //新增或删除包行转列
    insertOrDeletePackageColToRow(data) {
        let dynamic = this.colToRow, result = [],
            colField = dynamic.colField,
            valField = dynamic.valField,
            rowField = dynamic.rowField;
        //组装系统关键字字段
        let _row = {}
        _row[dynamic.rowField] = data[rowField] || (new Date()).valueOf();
        for (let colName in data) {
            if (colName.startsWith("$")) {
                _row[colName] = data[colName];
            }
        }
        for (let colName in data) {
            if (colName.startsWith("$"))
                continue;
            let row = cloneDeep(_row);
            row[colField] = colName;
            row[valField] = data[colName];
            result.push(row);
        }
        return result;
    }

    //更新包行转列
    updatePackageColToRow(data) {
        let dynamic = this.colToRow, result = [],
            colField = dynamic.colField,
            valField = dynamic.valField,
            rowField = dynamic.rowField;
        //组装系统关键字字段
        let _row = {}, _$old = {}
        _$old[dynamic.rowField] = data[paramKey.old][rowField]
        for (let colName in data) {
            if (colName.startsWith("$")) {
                _row[colName] = data[colName];
            }
        }

        for (let colName in data) {
            if (colName.startsWith("$")) {
                continue;
            }
            let row = cloneDeep(_row);
            let $old = cloneDeep(_$old);
            row[valField] = data[colName];
            $old[valField] = data[paramKey.old][colName];
            $old[colField] = colName;
            row[paramKey.old] = $old;
            result.push(row);
        }
        return result;
    }
    //转数据
    conversion(data) {
        if (data) {
            const result = {}
            for (const key in data) {
                const val = data[key]
                if (val === undefined)
                    continue;
                if (isDate(val)) {
                    result[key] = date.format(val, 'yyyy-MM-dd hh:mm:ss')
                }
                else if (val != null && typeof (val) == 'object' && !val[paramKey.modelFile]) {
                    result[key] = JSON.stringify(val);
                }
                else {
                    // if (paramKey.subSetkeyVal == val[paramKey.modelFile]){
                    //     delete val[paramKey.modelFile]
                    // }
                    result[key] = val
                }
            }
            return result
        }
    }
    normal(data) {
        var me = this
        data = this.conversion(data)
        data[paramKey.state] = paramKey.normalStateVal
        if (this.colToRow && this.colToRow.rowField) {
            me.dataPacks[paramKey.data] = me.dataPacks[paramKey.data].concat(this.insertOrDeletePackageColToRow(data))
        } else
            me.dataPacks[paramKey.data].push(data)
    }
    /**
    * showdoc
    * @title 新增数据
    * @description 新增数据
    * @method add
    * @param data 必选 json 数据
    * @return void
    */
    add(data) {
        var me = this
        data = this.conversion(data)
        data[paramKey.state] = paramKey.insertStateVal
        if (this.colToRow && this.colToRow.rowField) {
            me.dataPacks[paramKey.data] = me.dataPacks[paramKey.data].concat(this.insertOrDeletePackageColToRow(data))
        } else
            me.dataPacks[paramKey.data].push(data)
    }
    /**
    * showdoc
    * @title 更新数据
    * @description 更新数据
    * @method update
    * @param old 必选 json 旧数据（原数据）必须包含主键字段
    * @param data 必选 json 新数据（要更新的数据）
    * @return void
    * @demo
    * update({primaryField:1,Field1:0},{Field1:1,Field2:2})
    */
    update(old, data) {
        var me = this
        var row = {}
        data = this.conversion(data)
        old = this.conversion(old)
        for (var a in data) {
            if (data[a] === void 0) { continue }

            row[a] = data[a]
        }
        row[paramKey.state] = paramKey.updateStateVal
        row[paramKey.old] = old
        if (this.colToRow && this.colToRow.rowField) {
            me.dataPacks[paramKey.data] = me.dataPacks[paramKey.data].concat(this.updatePackageColToRow(row))
        } else
            me.dataPacks[paramKey.data].push(row)
    }
    setRedundant(old, data) {
    }
    /**
   * showdoc
   * @title 重写数据
   * @description 重写数据包，数据库中有该数据就更新，没有则新增
   * @method override
   * @param old 必选 json 旧数据（原数据）
   * @param data 必选 json 新数据（要更新或新增的数据）必须包含主键字段
   * @return void
   * @number 400
   */
    override(old, data) {
        if (!data) {
            data = old;
            old = null;
        }
        if (old)
            old = this.conversion(old)
        data = this.conversion(data)
        var me = this
        var row = {}
        for (var a in data) {
            if (data[a] === void 0) { continue }
            row[a] = data[a]
        }
        row[paramKey.state] = paramKey.overrideStateVal
        if (old)
            row[paramKey.old] = old

        if (this.colToRow && this.colToRow.rowField) {
            me.dataPacks[paramKey.data] = me.dataPacks[paramKey.data].concat(this.updatePackageColToRow(row))
        } else
            me.dataPacks[paramKey.data].push(row)
    }
    /**
   * showdoc
   * @title 删除数据 
   * @description 删除数据
   * @method remove
   * @param data 必选 json 要删除据数据，必须包含主键字段
   * @return void
   */
    remove(data) {
        var me = this
        data[paramKey.state] = paramKey.removeStateVal
        data = this.conversion(data)
        if (this.colToRow && this.colToRow.rowField) {
            me.dataPacks[paramKey.data] = me.dataPacks[paramKey.data].concat(this.insertOrDeletePackageColToRow(data))
        } else
            me.dataPacks[paramKey.data].push(data)
    }
    /**
    * @title 获取数据包字符串 
       * @method toString
       * @description 获取数据包字符串
       */
    toString() {
        return JSON.stringify(this.dataPacks)
    }
    /**
   * showdoc
   * @title 清空数据包 
   * @description 清空数据包
   * @method clear
   * @return void
   */
    clear() {
        this.dataPacks = {}
    }
    /**
   * showdoc
   * @title 获取数据包
   * @description 获取数据包
   * @method getDataPack
   * @return json
   */
    getDataPack() {
        return this.dataPacks
    }
    /**
  * showdoc
  * @title 是否含有数据包
  * @description 是否含有数据包
  * @method isEmpty
  * @return boolean
  */
    isEmpty() {
        if (this.dataPacks[paramKey.data] && this.dataPacks[paramKey.data].length > 0)
            return false;
        return true;
    }
    /**
* showdoc
* @title 追加保存包
* @description 追加保存包
* @method appendSavePack
* @param savePack 必选 json 保存包
* @return void
*/
    appendSavePack(savePack) {
        this._appendSavePack.push(savePack)
    }
    /**
  * showdoc
  * @title 提交数据 
  * @description 提交数据
  * @method save
  * @param options 非必选 json 提交时的附加参数
  * @param beforeSubmitCallBack 非必选 json 提交前回调,参数:options, param
  * @return Promise
  */
    save(options, beforeSubmitCallBack) {
        options = options || {};
        let param = {}
        this._appendSavePack.unshift(this.getDataPack())
        param[paramKey.root] = JSON.stringify(this._appendSavePack)
        // param[paramKey.version] = "1.0.0"
        param[paramKey.funcPath] = this.funcPath;
        param[paramKey.viewItemId] = this.viewItemId;
        
        if (this.extParam)
            Object.assign(param, this.extParam);
        Object.assign(param, options)

        if (beforeSubmitCallBack) {
            beforeSubmitCallBack.call(this, options, param, param[paramKey.root]);
        }

        let url = this.url
        if (this.pn) {
            if (url.indexOf('?') == -1)
                url = url + "?pn=" + this.pn;
            else
                url = url + "&pn=" + this.pn;
        }

        return this.request({
            url: url,
            method: 'post',
            data: param
        })


    }
}